<!-- eslint-disable -->
<template>
  <v-container fluid class="recording-container">
    <v-card class="mx-auto custom-card" max-width="600">
      <v-card-title class="justify-center">
        <v-img src="@/assets/logo.png" alt="Konvey" max-width="80" contain></v-img>
      </v-card-title>
      <v-card-text>
        <h1 class="text-center mb-4 custom-title">Start Your Recording</h1>

        <div v-if="!isRecording && !isPreviewMode">
          <v-radio-group v-model="recordingType" row class="mb-4 custom-radio-group">
            <v-radio label="Camera" value="camera"></v-radio>
            <v-radio label="Screen" value="screen"></v-radio>
            <v-radio label="Screen + Cam" value="screenCam"></v-radio>
          </v-radio-group>

          <v-select
            v-model="videoQuality"
            :items="videoQualityOptions"
            label="Select video quality"
            outlined
            class="mb-4 custom-select"
          ></v-select>

          <v-switch
            v-model="microphoneEnabled"
            label="Enable Microphone"
            class="mb-4 custom-switch"
          ></v-switch>

          <v-btn
            block
            color="primary"
            @click="startRecordingAndEnterPiP"
            class="mb-4 custom-btn"
          >
            Start Recording
          </v-btn>
        </div>

        <div v-if="isRecording" class="recording-controls">
          <v-btn @click="togglePause" color="primary" class="mr-2">
            {{ isPaused ? 'Resume' : 'Pause' }}
          </v-btn>
          <v-btn @click="stopRecording" color="error">
            Stop Recording
          </v-btn>
          <div class="recording-timer">{{ formattedRecordingTime }}</div>
        </div>

        <div v-if="isPreviewMode" class="preview mb-4">
          <video ref="previewVideo" :src="recordedVideoUrl" controls width="100%"></video>
          <v-row class="mt-2">
            <v-col>
              <v-btn color="primary" @click="reRecord" block class="custom-btn">
                Re-record
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="success" @click="uploadVideo" block class="custom-btn">
                Upload Video
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-overlay v-model="isCountingDown">
      <div style="position: relative">
        <div style="position: absolute; top: -40vh; transform: translate(-50%)">
          <h1 style="font-size: 300px">
            {{ countdownValue }}
          </h1>
        </div>
      </div>
    </v-overlay>

    <div v-if="isRecording && recordingType === 'camera'" class="camera-preview">
      <video ref="cameraPreview" autoplay muted></video>
    </div>
  </v-container>
</template>
<!-- eslint-disable -->

<script>
/* eslint-disable */
import RecordRTC from 'recordrtc';
import axios from 'axios';

export default {
  name: 'RecScreen',
  data() {
    return {
      recordingType: 'screen',
      videoQuality: 8000000,
      microphoneEnabled: true,
      isRecording: false,
      isPreviewMode: false,
      isCountingDown: false,
      countdownValue: 3,
      recorder: null,
      recordedVideo: null,
      recordedVideoUrl: null,
      videoQualityOptions: [
        { text: "SD (Standard Definition) 360p", value: 1000000 },
        { text: "FSD (Full Standard Definition) 480p", value: 2500000 },
        { text: "HD (High Definition) 720p", value: 5000000 },
        { text: "FHD (Full High Definition) 1080p", value: 8000000 },
        { text: "2k Video (Quad HD)", value: 16000000 },
        { text: "4k Video (UHD)", value: 40000000 },
      ],
      cameraStream: null,
      screenStream: null,
      audioStream: null,
      combinedStream: null,
      isPaused: false,
      pipVideo: null,
      miniPreview: null,
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      isPiPSupported: false,
      recordingStartTime: null,
      recordingTimer: null,
      totalRecordingTime: 0,
      pausedTime: 0,
      lastPauseTime: null,
    };
  },
  computed: {
    formattedRecordingTime() {
      const totalSeconds = Math.floor(this.totalRecordingTime / 1000);
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  },
  mounted() {
    // Check PiP support - explicitly checking for Firefox
    this.isPiPSupported = document.pictureInPictureEnabled && 
      !navigator.userAgent.toLowerCase().includes('firefox');
    
    // Add beforeunload event listener
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    async initializeRecording() {
      try {
        // If microphone is enabled, capture audio stream
        if (this.microphoneEnabled) {
          this.audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true
            }
          });
        }

        // If screen or screenCam is selected, capture screen stream
        if (this.recordingType === 'screen' || this.recordingType === 'screenCam') {
          const displayMediaOptions = {
            video: {
              width: { ideal: this.getResolutionWidth() },
              height: { ideal: this.getResolutionHeight() },
              frameRate: { ideal: 30 }
            },
            audio: !navigator.userAgent.toLowerCase().includes('firefox') // Firefox does not support system audio
          };

          this.screenStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);

          // Handle screen sharing stop
          this.screenStream.getVideoTracks()[0].addEventListener('ended', () => {
            this.stopRecording();
          });
        }

        // If camera or screenCam is selected, capture camera stream
        if (this.recordingType === 'camera' || this.recordingType === 'screenCam') {
          this.cameraStream = await navigator.mediaDevices.getUserMedia({
            video: true
          });
        }

        // ++
        // CAMERA MODE HERE

        // Combine tracks if multiple streams are used
        const tracks = this.getCombinedTracks();
        this.combinedStream = new MediaStream(tracks);

        // Start countdown or recording
        this.startCountdown();

      } catch (error) {
        console.error('Error initializing recording:', error);
        this.handleError('Failed to initialize recording. Please check your permissions and try again.');
      }
    },

    async startRecordingAndEnterPiP() {
      try {
        // Only attempt PiP if the recording type is "screenCam"
        if (this.recordingType === 'screenCam') {
          // Attempt to enter PiP mode immediately after user gesture
          await this.attemptEnterPiP();
        }

        // Initialize recording (get streams)
        await this.initializeRecording();

        // Start recording
        this.startRecording();
      } catch (error) {
        console.error('Failed to start recording or enter Picture-in-Picture mode:', error);
        this.setupMiniPreview(); // Fallback to mini-preview
      }
    },

    async attemptEnterPiP() {
      // Check if PiP is supported
      if (!document.pictureInPictureEnabled) {
        console.warn('Picture-in-Picture is not supported in this browser.');
        this.setupMiniPreview();
        return;
      }

      // Request camera access for the mirror preview
      try {
        const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true });
        const pipVideo = document.createElement('video');
        pipVideo.srcObject = cameraStream;
        pipVideo.muted = true;
        pipVideo.style.display = 'none';
        document.body.appendChild(pipVideo);

        // Ensure the video starts playing before requesting PiP
        await pipVideo.play();

        // Attempt to enter PiP mode
        await pipVideo.requestPictureInPicture();
        this.isPipActive = true;

        // Clean up PiP video when it exits
        pipVideo.addEventListener('leavepictureinpicture', () => {
          this.isPipActive = false;
          document.body.removeChild(pipVideo);
          cameraStream.getTracks().forEach(track => track.stop()); // Stop the camera stream
        });
      } catch (error) {
        console.error('Failed to enter Picture-in-Picture mode:', error);
        this.setupMiniPreview(); // Fallback to mini-preview
      }
    },

    getCombinedTracks() {
      const tracks = [];
      
      if (this.recordingType === 'camera') {
        tracks.push(...this.cameraStream.getVideoTracks());
      } else if (this.recordingType === 'screen') {
        tracks.push(...this.screenStream.getVideoTracks());
      } else if (this.recordingType === 'screenCam') {
        tracks.push(...this.screenStream.getVideoTracks());
      }

      if (this.microphoneEnabled && this.audioStream) {
        tracks.push(...this.audioStream.getAudioTracks());
      }

      const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
      if ((this.recordingType === 'screen' || this.recordingType === 'screenCam') && 
          !isFirefox && this.screenStream.getAudioTracks().length > 0) {
        tracks.push(...this.screenStream.getAudioTracks());
      }

      return tracks;
    },

    startCountdown() {
      this.isCountingDown = true;
      this.countdownValue = 3;
      const countdownInterval = setInterval(() => {
        this.countdownValue--;
        if (this.countdownValue === 0) {
          clearInterval(countdownInterval);
          this.isCountingDown = false;
          this.startRecording();
        }
      }, 1000);
    },

    startRecording() {
      // Setup preview for camera mode
      if (this.recordingType === 'camera') {
        this.$nextTick(() => {
          if (this.$refs.cameraPreview) {
            this.$refs.cameraPreview.srcObject = this.cameraStream;
          }
        });
      }
      
      const options = {
        type: 'video',
        mimeType: navigator.userAgent.toLowerCase().includes('firefox') ? 
          'video/webm' : 'video/webm;codecs=vp9',
        bitsPerSecond: this.videoQuality,
        frameInterval: 30,
        timeSlice: 1000,
      };

      this.recorder = new RecordRTC(this.combinedStream, options);
      this.recorder.startRecording();
      this.isRecording = true;
      this.recordingStartTime = Date.now();
      this.startRecordingTimer();

    },

    setupMiniPreview() {
      if (this.miniPreview) {
        this.miniPreview.remove();
      }

      this.miniPreview = document.createElement('div');
      this.miniPreview.style.cssText = `
        position: fixed;
        right: 20px;
        bottom: 20px;
        width: 220px;
        height: 180px;
        background: black;
        border: 2px solid #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.2);
        z-index: 9999;
        overflow: hidden;
        cursor: move;
        transition: transform 0.2s ease;
      `;

      const video = document.createElement('video');
      video.style.cssText = `
        width: 100%;
        height: 100%;
        object-fit: cover;
      `;
      video.autoplay = true;
      video.muted = true;
      video.playsInline = true;
      video.srcObject = this.cameraStream;

      this.setupDragHandlers();

      this.miniPreview.appendChild(video);
      document.body.appendChild(this.miniPreview);
    },

    setupDragHandlers() {
      this.miniPreview.addEventListener('mousedown', this.handleDragStart);
      this.miniPreview.addEventListener('touchstart', this.handleTouchStart);
      document.addEventListener('mousemove', this.handleDrag);
      document.addEventListener('touchmove', this.handleTouchMove);
      document.addEventListener('mouseup', this.handleDragEnd);
      document.addEventListener('touchend', this.handleDragEnd);
    },

    handleDragStart(e) {
      if (!this.miniPreview) return;
      
      this.isDragging = true;
      this.dragOffset = {
        x: (e.touches ? e.touches[0].clientX : e.clientX) - this.miniPreview.offsetLeft,
        y: (e.touches ? e.touches[0].clientY : e.clientY) - this.miniPreview.offsetTop
      };
      
      this.miniPreview.style.transform = 'scale(1.05)';
    },

    handleTouchStart(e) {
      e.preventDefault();
      this.handleDragStart(e);
    },

    handleDrag(e) {
      if (!this.isDragging || !this.miniPreview) return;

      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      const x = clientX - this.dragOffset.x;
      const y = clientY - this.dragOffset.y;

      const maxX = window.innerWidth - this.miniPreview.offsetWidth;
      const maxY = window.innerHeight - this.miniPreview.offsetHeight;

      this.miniPreview.style.left = `${Math.max(0, Math.min(x, maxX))}px`;
      this.miniPreview.style.top = `${Math.max(0, Math.min(y, maxY))}px`;
    },

    handleTouchMove(e) {
      e.preventDefault();
      this.handleDrag(e);
    },

    handleDragEnd() {
      this.isDragging = false;
      if (this.miniPreview) {
        this.miniPreview.style.transform = 'scale(1)';
      }
    },

    startRecordingTimer() {
      this.recordingTimer = setInterval(() => {
        if (!this.isPaused) {
          const currentTime = Date.now();
          this.totalRecordingTime = currentTime - this.recordingStartTime - this.pausedTime;
        }
      }, 1000);
    },

    togglePause() {
      if (this.isPaused) {
        this.recorder.resumeRecording();
        this.pausedTime += Date.now() - this.lastPauseTime;
        this.lastPauseTime = null;
      } else {
        this.recorder.pauseRecording();
        this.lastPauseTime = Date.now();
      }
      this.isPaused = !this.isPaused;
    },

    stopRecording() {
      if (!this.recorder) return;

      clearInterval(this.recordingTimer);
      this.recorder.stopRecording(() => {
        this.recordedVideo = this.recorder.getBlob();
        this.recordedVideoUrl = URL.createObjectURL(this.recordedVideo);
        this.isRecording = false;
        this.isPreviewMode = true;
        this.cleanupRecording();
      });
    },

    cleanupRecording() {
      this.stopAllStreams();
      this.cleanupPreviewElements();
      this.resetRecordingState();
    },

    stopAllStreams() {
      [this.cameraStream, this.screenStream, this.audioStream, this.combinedStream].forEach(stream => {
        if (stream) {
          stream.getTracks().forEach(track => {
            track.stop();
            track.enabled = false;
          });
        }
      });

      // Clear stream references
      this.cameraStream = null;
      this.screenStream = null;
      this.audioStream = null;
      this.combinedStream = null;
    },

    cleanupPreviewElements() {
      // Cleanup PiP
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture().catch(console.error);
      }
      if (this.pipVideo) {
        // Clean up will now happen inside `leavepictureinpicture` event
        this.pipVideo = null;
      }

      // Cleanup mini preview
      if (this.miniPreview) {
        this.removeDragHandlers();
        this.miniPreview.remove();
        this.miniPreview = null;
      }
    },

    removeDragHandlers() {
      if (!this.miniPreview) return;
      
      this.miniPreview.removeEventListener('mousedown', this.handleDragStart);
      this.miniPreview.removeEventListener('touchstart', this.handleTouchStart);
      document.removeEventListener('mousemove', this.handleDrag);
      document.removeEventListener('touchmove', this.handleTouchMove);
      document.removeEventListener('mouseup', this.handleDragEnd);
      document.removeEventListener('touchend', this.handleDragEnd);
    },

    resetRecordingState() {
      this.recordingStartTime = null;
      this.totalRecordingTime = 0;
      this.pausedTime = 0;
      this.lastPauseTime = null;
      this.isPaused = false;
    },

    getResolutionWidth() {
      const resolutions = {
        1000000: 640,   // 360p
        2500000: 854,   // 480p
        5000000: 1280,  // 720p
        8000000: 1920,  // 1080p
        16000000: 2560, // 2K
        40000000: 3840, // 4K
      };
      return resolutions[this.videoQuality] || 1280;
    },

    getResolutionHeight() {
      const resolutions = {
        1000000: 360,   // 360p
        2500000: 480,   // 480p
        5000000: 720,   // 720p
        8000000: 1080,  // 1080p
        16000000: 1440, // 2K
        40000000: 2160, // 4K
      };
      return resolutions[this.videoQuality] || 720;
    },

    reRecord() {
      this.isPreviewMode = false;
      if (this.recordedVideoUrl) {
        URL.revokeObjectURL(this.recordedVideoUrl);
      }
      this.recordedVideo = null;
      this.recordedVideoUrl = null;
      if (this.$refs.previewVideo) {
        this.$refs.previewVideo.src = '';
      }
    },

    async uploadVideo() {
      if (!this.recordedVideo) {
        this.handleError('No video to upload');
        return;
      }

      const formData = new FormData();
      formData.append('video', this.recordedVideo, `recorded-video-${Date.now()}.webm`);

      try {
        const response = await axios.post('/api/upload-video', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(`Upload progress: ${percentCompleted}%`);
            // You could add a progress bar here if desired
          },
        });
        
        this.handleSuccess('Video uploaded successfully!');
        this.reRecord();
      } catch (error) {
        console.error('Error uploading video:', error);
        this.handleError('Failed to upload video. Please try again.');
      }
    },

    handleError(message) {
      this.$vuetify.dialog.error({
        title: 'Error',
        text: message,
      });
    },

    handleSuccess(message) {
      this.$vuetify.dialog.success({
        title: 'Success',
        text: message,
      });
    },

    handleBeforeUnload(event) {
      if (this.isRecording) {
        event.preventDefault();
        event.returnValue = 'You have an active recording. Are you sure you want to leave?';
        return event.returnValue;
      }
    },
  },
  beforeDestroy() {
    this.cleanupRecording();
    clearInterval(this.recordingTimer);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
};
/* eslint-disable */
</script>

<style lang="scss" scoped>
.recording-container {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.custom-card {
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.custom-title {
  color: #333;
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.camera-preview {
  position: fixed;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  aspect-ratio: 16 / 9;
  background-color: #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(-1); /* Mirror effect for camera preview */
  }
}

.recording-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}

.recording-timer {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 0.5rem;
}

.countdown-card {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  .display-4 {
    color: white;
    font-weight: bold;
  }
}

.preview {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  video {
    background-color: #000;
  }
}

@media (max-width: 600px) {
  .custom-card {
    margin: 0.5rem;
  }

  .camera-preview {
    width: 95%;
  }

  .recording-controls {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

</style>
